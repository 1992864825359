import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    frTitle: string;
    icon: string;
    class: string;
    integer: any;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',     title: 'Dashboard',     frTitle: 'Tableau de Bord',   icon:'nc-bank',       class: 'dashboard',       integer: 25 }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
